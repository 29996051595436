import React,{Component} from 'react';

class Slider1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            x: 5, 
            y: 5  
        };
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.x !== this.props.x){
            this.setState({
                x: this.props.x*5+5, 
                y: this.state.y
            })
        }
    }

    startDrag(event) {
        event.preventDefault();
        let point = this.svg.createSVGPoint();
        if (event.touches) { event = event.touches[0]; }
        point.x = event.clientX;
        point = point.matrixTransform(this.svg.getScreenCTM().inverse());
        this.setState({dragOffset: {
          x: point.x - this.state.x,
        }});
        
        const mousemove = (event) => {
          event.preventDefault();
          if (event.touches) { event = event.touches[0]; }
          point.x = event.clientX;
          let cursor = point.matrixTransform(this.svg.getScreenCTM().inverse());
          if(cursor.x - this.state.dragOffset.x > 5 &&  cursor.x-this.state.dragOffset.x+45 < 925){
            this.setState({
                x: cursor.x - this.state.dragOffset.x,
                y: 5
            });
            }
        };
        
        const mouseup = (event) => {
          this.props.changeX(Math.round((this.state.x-5)/5));
          document.removeEventListener("mousemove", mousemove);
          document.removeEventListener("mouseup", mouseup);
          document.removeEventListener("touchmove", mousemove);
          document.removeEventListener("touchend", mouseup);
        };
        
        document.addEventListener("touchmove", mousemove);      
        document.addEventListener("touchend", mouseup);
        document.addEventListener('touchleave', mouseup);
        document.addEventListener('touchcancel', mouseup);

        document.addEventListener("mousemove", mousemove);      
        document.addEventListener("mouseup", mouseup);
      }

    render() { 
        return ( 
            <svg x="20" y="710" width="930" height="40" ref={(svg) => this.svg = svg}>
                <rect 
                    x={this.state.x}
                    y={this.state.y}
                    fill="white" 
                    width="45" 
                    height="30"
                    style={{"cursor" : "pointer"}}
                    ref={(e) => this.svgRectElem = e}
                    onMouseDown={(e) => this.startDrag(e, this.svgRectElem)}
                    onTouchStart={(e) => this.startDrag(e, this.svgRectElem)}
                />
	        </svg>
         );
    }
}
 
export default Slider1;