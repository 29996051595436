import React,{Component,Fragment} from 'react';

class Drag extends Component {

    constructor(props) {
        super(props);
        this.state = {
            x: 830, 
            y: 5,  
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.x !== this.props.x){
            this.setState({
                x: 830-this.props.x-5
            })
        }

        if(prevProps.y !== this.props.y){
            this.setState({              
                y: this.props.y+5
            })
        }
    }

    handleScroll(e) {
        this.props.Scroll(e.deltaY);
        console.log(e.deltaX);
        console.log(e.deltaY);
    }

    startDrag(event) {
        event.preventDefault();
        let point = this.svg.createSVGPoint();
        if (event.touches) { event = event.touches[0]; }
        point.x = event.clientX;
        point.y = event.clientY;
        point = point.matrixTransform(this.svg.getScreenCTM().inverse());
        this.setState({dragOffset: {
           x: point.x - this.state.x,
           y: point.y - this.state.y,
        }});
        
        const mousemove = (event) => {
          event.preventDefault();
          if (event.touches) { event = event.touches[0]; }
          point.x = event.clientX;
          point.y = event.clientY;
          let cursor = point.matrixTransform(this.svg.getScreenCTM().inverse());
          if(cursor.x - this.state.dragOffset.x > 0 &&  cursor.x-this.state.dragOffset.x+45 < 925){
            this.setState({
                x: cursor.x - this.state.dragOffset.x,
            });
            }
          if(cursor.y - this.state.dragOffset.y > 0 &&  cursor.y - this.state.dragOffset.y < 590){
            this.setState({
                 y: cursor.y - this.state.dragOffset.y
            });
            }
        };
        
        const mouseup = (event) => {
          this.props.changeX(Math.round((830-this.state.x-5)));
          this.props.changeY(Math.round((this.state.y-5)));
          document.removeEventListener("mousemove", mousemove);
          document.removeEventListener("mouseup", mouseup);
          document.removeEventListener("touchmove", mousemove);
          document.removeEventListener("touchend", mouseup);
        };
        
        document.addEventListener("touchmove", mousemove);      
        document.addEventListener("touchend", mouseup);
        document.addEventListener('touchleave', mouseup);
        document.addEventListener('touchcancel', mouseup);

        document.addEventListener("mousemove", mousemove);      
        document.addEventListener("mouseup", mouseup);
      }

    render() { 
        return ( 
            <svg x="0" y="0" width="930" height="640" ref={(svg) => this.svg = svg}>
                <rect 
                x={0}
                y={0}
                width={"100%"} 
                height={"100%"} 
                fill='transparent' 
                cursor={"move"}
                ref={(e) => this.svgRectElem = e}
                onMouseDown={(e) => this.startDrag(e, this.svgRectElem)}
                onTouchStart={(e) => this.startDrag(e, this.svgRectElem)}
                onWheel={this.handleScroll}
                />
            </svg>
     )}
}
 
export default Drag;